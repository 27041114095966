import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);

  &:focus {
    border-color: #85b7d9;
    outline: none;
  }
  &:-webkit-autofill {
    box-shadow: inset 0 0 0 100px ivory;
    border-color: #e5dfa1;
    &:focus {
      border-color: #d5c315;
    }
  }

  ${({ hasError }) => {
    if (hasError) {
      return `
        background-color: #fff6f6;
        border-color: #e0b4b4;
        &::placeholder {
          color: #e0b4b4;
        }
        &:focus {
          border-color: #e0b4b4;
          &::placeholder {
            color: #da9796;
          }
        }
      `;
    }
  }}
`;
