import { first, get, kebabCase, last } from 'lodash';

import { CountryCodes } from 'src/common/enums';
import {
  isCountryCodeID,
  isCountryCodeVNOrID,
} from 'src/common/hooks/useCountryData';
import { User } from 'src/global/models/User';

import {
  FIRST_DEFAULT_STEP,
  ONBOARDING_CONFIG_BY_USAGE,
  OnboardingType,
  OnboardingUsage,
  StepNames,
  STEPS_BY_TYPE,
} from '../constants';

export const getStepIndex = (steps: StepNames[], StepName: StepNames) =>
  steps.findIndex((step) => step === StepName);

export const getHasCompletedOnboarding = (
  lastStep: StepNames,
  onboardingType: OnboardingType
) => {
  if (!onboardingType) {
    return false;
  }
  const steps = STEPS_BY_TYPE[onboardingType];
  return last(steps) === lastStep;
};

export const getLastOnboardingStep = (user: User): StepNames => {
  return get(
    user.metadata,
    `[${OnboardingUsage.OnboardingGuided}].lastStep`,
    FIRST_DEFAULT_STEP
  );
};

export const getOnboardingRedirectURL = (
  lastStep: StepNames = FIRST_DEFAULT_STEP
) => {
  const { route } =
    ONBOARDING_CONFIG_BY_USAGE[OnboardingUsage.OnboardingGuided];
  return `/${route}/${kebabCase(lastStep)}`;
};

export const getOnboardingUsageRedirectURL = (user: User) => {
  const lastStep = getLastOnboardingStep(user);
  return getOnboardingRedirectURL(lastStep);
};

export const getOnboardingType = (
  countryCode: CountryCodes,
  whatsappNumber?: string
) => {
  const shouldShowWhatsappForm =
    isCountryCodeID(countryCode) && !whatsappNumber;
  if (shouldShowWhatsappForm) {
    return OnboardingType.JobSeekerShortenedWithWhatsapp;
  }

  return isCountryCodeVNOrID(countryCode)
    ? OnboardingType.JobSeekerShortened
    : OnboardingType.JobSeeker;
};

export const isLastStepMatchInitialStepInOnboardingType = (
  lastStep: StepNames,
  onboardingType: OnboardingType
): boolean => {
  if (!onboardingType) {
    return true;
  }

  const steps = STEPS_BY_TYPE[onboardingType];
  return first(steps) === lastStep;
};
