import React, { useEffect } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'glints-aries';
import trim from 'lodash/trim';
import { get } from 'lodash-es';
import { Router } from 'next/router';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  useForm,
} from 'react-hook-form';
import { FormattedMessage, IntlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Action, ActionFunctionAny } from 'redux-actions';
import * as Yup from 'yup';

import { TRACKING_CLASSNAMES } from 'src/common/constants';
import { ROUTES } from 'src/common/routes';
import { GoogleTagManager } from 'src/components/GoogleTagManager';
import {
  TextField,
  textFieldType,
} from 'src/components/LegacyTextField/TextField';
import { ExternalSignInButtons } from 'src/components/LoginModal/ExternalSignInButtons';
import { OrDivider } from 'src/components/OrDivider';
import {
  ErrorMessage,
  MessageHeader,
  WarningMessage,
} from 'src/components/SemanticUI/Message';
import { LoginButtonClicked } from 'src/modules/Session/GTMActions';

import { ReduxThunkAction } from '../../global/store';
import { LoginGTMOptions } from '../../modules/Session/Actions';
import * as Styles from './LoginForm.sc';
import {
  inputPlaceholderMessages,
  loginAttemptsExceededErrorMessages,
  loginBadRequestErrorMessages,
  loginNetworkErrorMessages,
  loginUnknownErrorMessages,
  validationMessage,
} from './messages';

type FormValues = {
  email: string;
  password: string;
};

type InputProps = {
  id: string;
  name: keyof FormValues;
  control: Control<FormValues, object>;
  placeholder?: string;
  type: textFieldType;
  label: string;
  autoFocus?: boolean;
  normalize?: (...args: any[]) => string;
};

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage(validationMessage.emailRequired))
      .email(intl.formatMessage(validationMessage.invalidEmail)),
    password: Yup.string().required(
      intl.formatMessage(validationMessage.passwordRequired)
    ),
  });

const Input = ({ name, control, ...props }: InputProps) => {
  const isPasswordField = name === 'password';
  const ref = React.useRef(null);

  // workaround: setting autoFocus on the input tag does not work
  React.useEffect(() => {
    const inputRef = ref.current;
    if (props.autoFocus && inputRef) {
      setTimeout(() => {
        inputRef.focus();
      }, 150);
    }
  }, [props.autoFocus]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const hasError = fieldState.isTouched && Boolean(fieldState.error);

        return (
          <Styles.Field hasError={hasError}>
            <TextField {...field} {...props} small={true} />
            {hasError && (
              <Styles.ValidationError
                data-cy="login-error-message"
                hasMarginBottom={isPasswordField}
              >
                {fieldState.error?.message}
              </Styles.ValidationError>
            )}
          </Styles.Field>
        );
      }}
    />
  );
};

interface EmailLoginFormProps {
  router: Router;
  login: (
    email: string,
    password: string,
    apolloClient?: ApolloClient<object>,
    gtmOptions?: LoginGTMOptions
  ) => ReduxThunkAction<any>;
  closeLoginSignUpModal: () => ReduxThunkAction<any>;
  intl: IntlShape;
  setLoginFormHover: ActionFunctionAny<Action<any>>;
  unsetLoginFormHover: ActionFunctionAny<Action<any>>;
  loginError: any;
  isModal: boolean;
  productGtm: string;
}

export default function EmailLoginForm({
  router,
  login,
  closeLoginSignUpModal,
  intl,
  setLoginFormHover,
  unsetLoginFormHover,
  loginError,
  isModal,
  productGtm,
}: EmailLoginFormProps) {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const onSubmit = (values: FormValues) => {
    dispatch(login(values.email, values.password, apolloClient));
  };

  const handleForgotPasswordClick = () => {
    router.push({
      pathname: `/${ROUTES.forgotPassword}`,
      query: router.query,
    });

    closeLoginSignUpModal();
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: { email: '', password: '' },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema(intl)),
  });

  const showWarning = router.query.next;

  let errorHeader: string | null = null;
  let errorMessage: string | null = null;
  let errorExtraProps = {};
  if (loginError) {
    if (loginError.message === 'Network Error') {
      errorHeader = intl.formatMessage(loginNetworkErrorMessages.header);
      errorMessage = intl.formatMessage(loginNetworkErrorMessages.message);
    } else if (
      loginError.status === 401 ||
      (loginError.status === 400 && loginError.statusText === 'Bad Request')
    ) {
      errorHeader = intl.formatMessage(loginBadRequestErrorMessages.header);
      errorMessage = intl.formatMessage(loginBadRequestErrorMessages.message);
      errorExtraProps = { 'data-cy': 'incorrect_credential_toast' };
    } else if (loginError.status === 429) {
      errorHeader = intl.formatMessage(
        loginAttemptsExceededErrorMessages.header
      );
      errorMessage = intl.formatMessage(
        loginAttemptsExceededErrorMessages.message
      );
    } else {
      errorHeader = intl.formatMessage(loginUnknownErrorMessages.header);
      errorMessage = intl.formatMessage(loginUnknownErrorMessages.message);
    }
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch(LoginButtonClicked({ success: false, errors: errorMessage }));
    }
  }, [dispatch, errorMessage]);

  const onError = (errors: FieldErrors<FormValues>) => {
    const errorMessages = Object.keys(errors).map(
      (key) => (get(errors, key) as FieldError).message
    );
    dispatch(
      LoginButtonClicked({ success: false, errors: errorMessages.join(',') })
    );
  };

  return (
    <Styles.Body>
      <Styles.UpperInnerBody>
        <Styles.ContentWrapper>
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            onMouseEnter={setLoginFormHover}
            onMouseLeave={unsetLoginFormHover}
          >
            {showWarning && (
              <WarningMessage>
                <FormattedMessage
                  id="login-form.warning.title"
                  defaultMessage="Login Required"
                  tagName={MessageHeader}
                />
                <FormattedMessage
                  id="login-form.warning.description"
                  defaultMessage="The page you were trying to visit requires you to login."
                  tagName="p"
                />
              </WarningMessage>
            )}
            <Input
              type="text"
              id="login-form-email"
              name="email"
              label={intl.formatMessage(inputPlaceholderMessages.email)}
              autoFocus={true}
              control={control}
              normalize={trim}
            />
            <Input
              type="password"
              id="login-form-password"
              name="password"
              label={intl.formatMessage(inputPlaceholderMessages.password)}
              control={control}
            />
            <Styles.ForgotPasswordLinkWrapper>
              <Styles.ForgotPasswordLink onClick={handleForgotPasswordClick}>
                <FormattedMessage
                  id="forgot.your.password"
                  defaultMessage="Forgot your password?"
                />
              </Styles.ForgotPasswordLink>
            </Styles.ForgotPasswordLinkWrapper>
            {loginError && (
              <ErrorMessage {...errorExtraProps}>
                <MessageHeader>{errorHeader}</MessageHeader>
                <p>{errorMessage}</p>
              </ErrorMessage>
            )}
            <GoogleTagManager tag="amplitude_login-click_login_page_email">
              <Styles.SubmitButton
                type="submit"
                data-cy="submit_btn_login"
                className={TRACKING_CLASSNAMES.loginSubmitButton}
                disabled={isSubmitting}
              >
                <If condition={isSubmitting}>
                  <Loading />
                </If>
                <If condition={!isSubmitting}>
                  <FormattedMessage
                    id="login"
                    defaultMessage="Login"
                    tagName="span"
                  />
                </If>
              </Styles.SubmitButton>
            </GoogleTagManager>
          </form>
        </Styles.ContentWrapper>
        <OrDivider bold={isModal} upperCase={isModal} showLine={isModal} />
        <Styles.ContentWrapper>
          <ExternalSignInButtons
            signInSource="login"
            gtmPayloadString={productGtm}
          />
        </Styles.ContentWrapper>
      </Styles.UpperInnerBody>
    </Styles.Body>
  );
}
