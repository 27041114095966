import React from 'react';
import { Greyscale } from 'glints-aries';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  bold?: boolean;
  upperCase?: boolean;
  showLine?: boolean;
};

const Divider = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  color: ${Greyscale.grey};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ showLine }) => {
    if (showLine) {
      return `
      &:before,
      &:after {
        content: '';
        width: calc(50% - 72px);
        height: 1px;
        background-color: ${Greyscale.lightgrey};
      }
      &:before {
        margin-right: 16px;
      }
      &:after {
        margin-left: 16px;
      }`;
    }
    return ``;
  }}
`;

export const OrDivider: React.FC<React.PropsWithChildren<Props>> = ({
  bold,
  upperCase,
  showLine,
}) => {
  return (
    <Divider bold={bold} upperCase={upperCase} showLine={showLine}>
      <FormattedMessage id="or" defaultMessage="or" />
    </Divider>
  );
};
