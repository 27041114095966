import { defineMessages } from 'react-intl';

export const loginNetworkErrorMessages = defineMessages({
  header: {
    id: 'login-form_error-header_network',
    defaultMessage: 'Network error.',
  },
  message: {
    id: 'login-form_error-message_network',
    defaultMessage: "Please check if you're connected to the Internet.",
  },
});

export const loginBadRequestErrorMessages = defineMessages({
  header: {
    id: 'login.error.header.invalid.email.or.password',
    defaultMessage: 'Invalid email or password.',
  },
  message: {
    id: 'login.error.message.invalid.email.or.password',
    defaultMessage: 'Please check that the email address you entered is valid.',
  },
});

export const loginAttemptsExceededErrorMessages = defineMessages({
  header: {
    id: 'login-form_error-header_login-attempts-exceeded',
    defaultMessage: 'Failed to login',
  },
  message: {
    id: 'login-form_error-message_login-attempts-exceeded',
    defaultMessage:
      'Try to login again in several minutes. You can also change your password.',
  },
});

export const loginUnknownErrorMessages = defineMessages({
  header: {
    id: 'login-form_error-header_unknown',
    defaultMessage: 'Unknown error.',
  },
  message: {
    id: 'login-form_error-message_unknown',
    defaultMessage:
      'We have encountered an unknown problem. Our engineers have been notified..',
  },
});

export const inputPlaceholderMessages = defineMessages({
  email: {
    id: 'login-form_email-input_placeholder',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
});

export const validationMessage = defineMessages({
  emailRequired: {
    id: 'login-form.error.email-required',
    defaultMessage: 'Email is required',
  },
  invalidEmail: {
    id: 'login-form.error.invalid-email',
    defaultMessage: 'Enter a valid email',
  },
  passwordRequired: {
    id: 'login-form.error.password-required',
    defaultMessage: 'Password is required',
  },
});
