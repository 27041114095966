import {
  Button as AriesButton,
  Greyscale,
  PrimaryColor,
  SecondaryColor,
  Typography,
} from 'glints-aries';
import { Divider as AriesDivider, Link } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { Input as SemanticUIInput } from 'src/components/SemanticUI/Input';
import { Message as SemanticUIMessage } from 'src/components/SemanticUI/Message';

const { Paragraph: TypographyParagraph } = Typography;
const fieldErrorColor = '#9f3a38';

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10em;
`;

export const PointerHolder = styled.div`
  cursor: pointer;
`;

export const UnderlinedText = styled.span`
  text-decoration: underline;
  font-weight: bold;
`;

export const Highlight = styled.span`
  color: ${SecondaryColor.actionblue};
`;

export const Message = styled(SemanticUIMessage)`
  display: flex;
  align-items: center;
  margin-top: 0;

  svg {
    height: 42px;
    width: 42px;
    margin-right: 11px;
    fill: rgba(0, 0, 0, 0.87);
    opacity: 0.8;
  }
`;

export const Content = styled.div`
  color: #9b9b9b;
`;

export const Body = styled.div`
  background-color: #fff;
`;

export const UpperInnerBody = styled.div`
  padding: 2em;
`;

export const Label = styled.label`
  align-self: flex-start;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 15px;
  text-align: left;
`;

export const IconWrapper = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  opacity: 0.5;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Field = styled.div<{ hasError: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ hasError }) => {
    if (hasError) {
      return `
        ${Label}, ${IconWrapper} {
          color: ${fieldErrorColor};
        }

        ${IconWrapper} > svg {
          fill: ${fieldErrorColor};
        }
      `;
    }
    return '';
  }}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(SemanticUIInput)`
  height: 40px;
  padding: 11px 16px 11px 40px;
  font-size: 16px;
`;

export const ValidationError = styled(TypographyParagraph).attrs({
  variant: 'caption',
  color: PrimaryColor.glintsred,
})<{ hasMarginBottom: boolean }>`
  margin-top: 5px;
  text-align: left;

  ${({ hasMarginBottom }) => {
    if (hasMarginBottom) {
      return `
        margin-bottom: 10px;
      `;
    }
    return '';
  }}
`;

export const SubmitButton = styled(AriesButton).attrs((props) => ({
  variant: 'yellow',
  className: props?.className,
}))`
  position: relative;
  letter-spacing: 0.88px;
  width: 100%;
  height: 42px;
  .solid-shadow-btn-content {
    letter-spacing: 0.88px;
  }

  .aries-loading {
    position: absolute;
    top: 9px;
    left: calc(50% - 12px);

    & > div {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ForgotPasswordLinkWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

export const ForgotPasswordLink = styled(AriesButton).attrs({
  type: 'button', // to avoid the pressing enter keyboard triggering this button click event
  variant: 'link',
})`
  margin-top: -16px;
  font-size: 16px;
  text-align: left;
`;

export const SignupLink = styled.div`
  cursor: pointer;
  font-size: 16px;
`;

export const CenterText = styled.p`
  text-align: center;
  margin-bottom: 15px;
`;

export const SocialMediaButtonWrappers = styled.div`
  width: 40px;
  height: 40px;
`;

export const ContentWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SocialMediaLoginsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
`;

export const Divider = styled(AriesDivider)`
  height: 1px;
`;

export const GlintsLink = styled(Link)`
  display: flex;
  justify-content: center;
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const SignUpSmallText = styled.div`
  padding: 0.5em 1em;
  margin-top: 0.5em;
  color: ${Greyscale.grey};
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  color: ${Greyscale.grey};
  margin: 0;
  align-self: center;
`;
