import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Loading } from 'glints-aries';
import { Router, withRouter } from 'next/router';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Action, ActionFunctionAny } from 'redux-actions';

import {
  closeLoginSignUpModal,
  setLoginFormHover,
  unsetLoginFormHover,
} from 'src/actions/app';
import {
  dismissCloseableNotification,
  showCloseableNotification,
} from 'src/actions/notifications';
import { updateMeta } from 'src/actions/user/me';
import authenticate from 'src/common/authenticate';
import {
  JOB_DETAILS_PAGE_PATH,
  SOURCED_JOB_DETAILS_PAGE_PATH,
  TRACKING_CLASSNAMES,
} from 'src/common/constants';
import { Products } from 'src/common/enums';
import { useCountry } from 'src/common/hooks';
import { isCountryCodeVNOrID } from 'src/common/hooks/useCountryData';
import { routerPathToProductGtmMap } from 'src/common/hooks/useProductGtmQuery';
import { getUtmParams } from 'src/common/links';
import { ROUTES } from 'src/common/routes';
import { trackSocialButtonClicked } from 'src/common/tracking/trackingUtils';
import { isAbsoluteURL } from 'src/common/URLUtils';
import { isXssPayload } from 'src/common/utils/security';
import { EmployersLink as SharedEmployersLink } from 'src/components/links';
import { ExternalSignInButtons } from 'src/components/LoginModal/ExternalSignInButtons';
import { OrDivider } from 'src/components/OrDivider';
import { User, UserRole } from 'src/global/models/User';
import { ReduxThunkAction, State } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';
import { StepNames } from 'src/modules/OnboardingGuided/constants';
import { getOnboardingRedirectURL } from 'src/modules/OnboardingGuided/utils';
import {
  login,
  LoginGTMOptions,
  sendEmailVerificationToken,
} from 'src/modules/Session/Actions';
import { LoginButtonClicked as LoginButtonClickedAction } from 'src/modules/Session/GTMActions';
import {
  getIsMfaRequired,
  getLoginError,
  getUserMeta,
} from 'src/modules/Session/Selectors';
import {
  getPrimaryPathName,
  getRouterQueryRedirect,
} from 'src/modules/SignUp/helper';
import { getIsShowLoginPopupEnabled } from 'src/modules/Unleash/Selectors';
import { getFetchingMe, getUser } from 'src/selectors/user';

import EmailLoginForm from './EmailLoginForm';
import * as Styles from './LoginForm.sc';

const Underline = (...chunks: React.ReactNode[]) => (
  <Styles.UnderlinedText>{chunks}</Styles.UnderlinedText>
);

const EmployersLink = (...chunks: React.ReactNode[]) => (
  <SharedEmployersLink>{chunks}</SharedEmployersLink>
);

type Props = {
  loginError: any;
  me: User;
  isFetchingMe: boolean;
  userMeta: Record<string, unknown>;
  autoCloseForVerifyAlert: number;
  autoCloseForMailSentAlert: number;

  login: (
    email: string,
    password: string,
    gtmOptions: LoginGTMOptions
  ) => ReduxThunkAction<any>;
  closeLoginSignUpModal: () => ReduxThunkAction<any>;
  setLoginFormHover: ActionFunctionAny<Action<any>>;
  unsetLoginFormHover: ActionFunctionAny<Action<any>>;
  updateMeta: ActionFunctionAny<Action<any>>;
  sendEmailVerificationToken: (onSuccess?: () => void) => ReduxThunkAction<any>;
  dismissCloseableNotification: ActionFunctionAny<Action<any>>;
  showCloseableNotification: ActionFunctionAny<Action<any>>;
  LoginButtonClicked: ActionFunctionAny<Action<any>>;

  afterSignUpClicked?: () => void;

  authenticated: boolean;
  submitting: boolean;
  router: Router;
  intl: IntlShape;
  isModal: boolean;

  isMfaRequired: boolean;
};

const LoginForm = ({
  authenticated,
  me,
  isFetchingMe,
  isMfaRequired,
  router,
  closeLoginSignUpModal,
  userMeta,
  updateMeta,
  autoCloseForVerifyAlert,
  showCloseableNotification,
  isModal,
  afterSignUpClicked,
  LoginButtonClicked,
  autoCloseForMailSentAlert,
  loginError,
  intl,
}: Props) => {
  const country = useCountry();
  const shouldShortenOnboarding = isCountryCodeVNOrID(country);
  const selectMeValue = useMemo(() => {
    return me;
  }, [me]);
  const { email, role, isVerified } = selectMeValue || {};
  const onboardingMetadata = selectMeValue?.metadata?.onboarding || {};
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { next, nextAs, ...queries } = router.query;
  const marketingQueryUtmParams = getUtmParams(queries);

  const asPath = router.asPath;
  const isApplyingProcess = asPath.includes('apply');
  const isJobDetailsPage = router.pathname.includes(JOB_DETAILS_PAGE_PATH);
  const isSourcedJobDetailsPage = router.pathname.includes(
    SOURCED_JOB_DETAILS_PAGE_PATH
  );

  const [showEmailLoginForm, setShowEmailLoginForm] = React.useState(false);
  const isShowLoginPopupEnabled = useSelector(getIsShowLoginPopupEnabled);

  const handleSignUpLinkClick = async (e: SyntheticEvent) => {
    e.preventDefault();

    const redirectToSignUp = () => {
      const query = getRouterQueryRedirect(router);
      const { next: _next, nextAs: _nextAs, ...queryWithoutNext } = query;
      const isPopup = isShowLoginPopupEnabled && router.pathname !== '/login';
      return router.push({
        pathname: '/signup',
        // Remove next query param in popup to increase onboarding conversion
        query: isPopup ? queryWithoutNext : query,
      });
    };

    if (afterSignUpClicked && typeof afterSignUpClicked === 'function') {
      /** call afterSignUpClicked after redirection */
      await redirectToSignUp();
      afterSignUpClicked();
    } else {
      redirectToSignUp();
    }

    closeLoginSignUpModal();
  };

  const renderVerifyEmailSendMessage = () => (
    <Styles.PointerHolder onClick={dismissCloseableNotification}>
      <FormattedMessage
        id="email.verification.sent"
        defaultMessage="An email verification email has been sent to your email. Please check your email inbox for instructions."
        tagName="span"
      />{' '}
      &nbsp;&nbsp;&nbsp;
    </Styles.PointerHolder>
  );

  React.useEffect(() => {
    if (authenticated) {
      LoginButtonClicked({ success: true, errors: '' });
    }
  }, [LoginButtonClicked, authenticated, role]);

  React.useEffect(() => {
    if (!authenticated) {
      return;
    }
    const sendEmailVerify = () => {
      sendEmailVerificationToken();
      showCloseableNotification({
        message: renderVerifyEmailSendMessage(),
        autoCloseInterval: autoCloseForMailSentAlert,
      });
    };

    const shouldRedirectToNextPath = () => {
      return (
        ((next && !isAbsoluteURL(next as string)) ||
          (nextAs && !isAbsoluteURL(nextAs as string))) &&
        !isXssPayload(next as string)
      );
    };

    const hasVisitedOnboarding = () => {
      return Boolean(onboardingMetadata?.lastStep);
    };

    const shouldStartOnboarding = !hasVisitedOnboarding();
    const hasJobLocationPreferencesFilled =
      selectMeValue?.isJobLocationPreferencesFilled;
    const hasJobRolePreferencesFilled =
      selectMeValue?.isJobRolePreferencesFilled;

    const renderVerifyEmailMessage = () => (
      <Styles.PointerHolder onClick={sendEmailVerify}>
        <FormattedMessage
          id="notification.email.verify.pointer"
          defaultMessage="Hey there! Do remember to verify your email by clicking on the email verification link in your inbox. If you don't have it, click <Underline>here</Underline> to receive the email verification email."
          values={{ Underline }}
          tagName="span"
        />
      </Styles.PointerHolder>
    );

    const redirectIfAuthenticated = async () => {
      if (isApplyingProcess || isJobDetailsPage || isSourcedJobDetailsPage) {
        if (isJobDetailsPage || isSourcedJobDetailsPage) {
          window.location.reload();
        } else {
          router.push({ pathname: next as any, query: queries }, nextAs as any);
        }
      }

      if (isFetchingMe) {
        return;
      }
      setIsRedirecting(true);

      if (role === UserRole.COMPANY) {
        router.push('/redirect');
      } else {
        if (Object.keys(userMeta).length) {
          await updateMeta(userMeta);
        }
        if (role && role !== UserRole.CANDIDATE) {
          router.push({
            pathname: `/${ROUTES.opportunitiesJobs}`,
            query: marketingQueryUtmParams,
          });
        } else if (!hasJobRolePreferencesFilled || shouldStartOnboarding) {
          const onboardingRedirectURL = getOnboardingRedirectURL(
            StepNames.JobInterest
          );

          router.push({
            pathname: onboardingRedirectURL,
            query: marketingQueryUtmParams,
          });
        } else if (!hasJobLocationPreferencesFilled) {
          const onboardingRedirectURL = getOnboardingRedirectURL(
            StepNames.JobPreferences
          );

          router.push({
            pathname: onboardingRedirectURL,
            query: marketingQueryUtmParams,
          });
        } else if (!isShowLoginPopupEnabled && shouldRedirectToNextPath()) {
          router.push({ pathname: next as any, query: queries }, nextAs as any);
        } else if (shouldRedirectToNextPath()) {
          router.push({ pathname: next as any, query: queries }, nextAs as any);
        } else {
          router.push({
            pathname: `/${ROUTES.opportunitiesJobsRecommended}`,
            query: marketingQueryUtmParams,
          });
          if (authenticated && !isVerified) {
            showCloseableNotification({
              message: renderVerifyEmailMessage(),
              autoCloseInterval: autoCloseForVerifyAlert,
            });
          }
        }
      }

      closeLoginSignUpModal();
      setIsRedirecting(false);
    };
    if (!isRedirecting) {
      redirectIfAuthenticated();
    }
  }, [
    LoginButtonClicked,
    authenticated,
    autoCloseForMailSentAlert,
    autoCloseForVerifyAlert,
    closeLoginSignUpModal,
    country,
    email,
    isFetchingMe,
    isModal,
    isRedirecting,
    isShowLoginPopupEnabled,
    isVerified,
    selectMeValue,
    onboardingMetadata?.lastStep,
    role,
    router,
    shouldShortenOnboarding,
    showCloseableNotification,
    updateMeta,
    userMeta,
    isApplyingProcess,
    isJobDetailsPage,
    next,
    nextAs,
    queries,
    marketingQueryUtmParams,
    isSourcedJobDetailsPage,
  ]);

  React.useEffect(() => {
    if (!isMfaRequired) {
      return;
    }
    router.push('/redirect');
    closeLoginSignUpModal();
  }, [closeLoginSignUpModal, isMfaRequired, router]);

  const productGtm =
    routerPathToProductGtmMap[getPrimaryPathName(router.pathname)] ||
    Products.Marketplace;
  const showLoadingScreen =
    (authenticated || isMfaRequired || isRedirecting) && !isModal;

  return (
    <div>
      <Choose>
        <When condition={showLoadingScreen}>
          <Styles.LoaderWrapper>
            <Loading />
          </Styles.LoaderWrapper>
        </When>
        <When condition={showEmailLoginForm}>
          <EmailLoginForm
            router={router}
            login={login}
            closeLoginSignUpModal={closeLoginSignUpModal}
            intl={intl}
            setLoginFormHover={setLoginFormHover}
            unsetLoginFormHover={unsetLoginFormHover}
            loginError={loginError}
            isModal={isModal}
            productGtm={productGtm}
          />
        </When>
        <Otherwise>
          <Styles.Body>
            <Styles.UpperInnerBody>
              <Styles.ContentWrapper>
                <ExternalSignInButtons
                  signInSource="login"
                  gtmPayloadString={productGtm}
                />
              </Styles.ContentWrapper>
              <Styles.ContentWrapper>
                <OrDivider
                  bold={isModal}
                  upperCase={isModal}
                  showLine={isModal}
                />
              </Styles.ContentWrapper>

              <Styles.GlintsLink
                onClick={() => {
                  trackSocialButtonClicked({
                    type: 'email',
                    page: router.asPath.includes('signup') ? 'signup' : 'login',
                  });
                  setShowEmailLoginForm(true);
                }}
                aria-label="Login with Email button"
              >
                <FormattedMessage
                  id="login.button.email.login"
                  defaultMessage="Login with Email"
                />
              </Styles.GlintsLink>
            </Styles.UpperInnerBody>

            <Styles.Divider />

            <Styles.UpperInnerBody>
              <Styles.SignUpSmallText>
                <Styles.Paragraph>
                  <span>
                    <FormattedMessage
                      id="login-page_signup"
                      defaultMessage="Don’t have Glints account?"
                    />
                  </span>
                </Styles.Paragraph>
                <Styles.GlintsLink
                  className={TRACKING_CLASSNAMES.loginSubmitButton}
                  onClick={handleSignUpLinkClick}
                >
                  <FormattedMessage id="signup" defaultMessage="Sign Up" />
                </Styles.GlintsLink>
              </Styles.SignUpSmallText>
              <Styles.SignUpSmallText>
                <FormattedMessage
                  id="login-page_employers-link"
                  defaultMessage="If you’re an employer, visit <EmployersLink>this page</EmployersLink> instead."
                  tagName="p"
                  values={{
                    EmployersLink,
                  }}
                />
              </Styles.SignUpSmallText>
            </Styles.UpperInnerBody>
          </Styles.Body>
        </Otherwise>
      </Choose>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isMfaRequired: getIsMfaRequired(state),
  loginError: getLoginError(state),
  me: getUser(state),
  isFetchingMe: getFetchingMe(state),
  userMeta: getUserMeta(state),
  autoCloseForVerifyAlert:
    getConfig(state).AUTO_CLOSE_INTERVAL_FOR_VERIFY_ALERT,
  autoCloseForMailSentAlert: getConfig(state).AUTO_CLOSE_FOR_MAIL_SENT_ALERT,
});

const mapDispatchToProps = {
  login,
  closeLoginSignUpModal,
  setLoginFormHover,
  unsetLoginFormHover,
  updateMeta,
  sendEmailVerificationToken,
  dismissCloseableNotification,
  showCloseableNotification,
  LoginButtonClicked: LoginButtonClickedAction,
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(authenticate(LoginForm, true));
