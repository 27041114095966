import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { GoogleTagManager } from 'src/components/GoogleTagManager';

import { State } from '../../global/store';
import * as Styled from './ExternalSignInButtons.sc';

const LoginWithFacebookButton = dynamic(
  () => import('src/components/LoginWithFacebookButton')
);

const LoginWithLinkedInButton = dynamic(
  () => import('src/components/LoginWithLinkedinButton')
);

const SignInWithGoogle = dynamic(() =>
  import('src/components/SignInWithGoogle').then((mod) => mod.SignInWithGoogle)
);

export type SignInSource = 'login' | 'sign up' | 'one tap';

type ExternalSignInButtonsProps = {
  gtmPayloadString: string;
  showLinkedIn?: boolean;
  signInSource: SignInSource;
};

export const ExternalSignInButtons = ({
  gtmPayloadString,
  signInSource,
  showLinkedIn = true,
}: ExternalSignInButtonsProps) => {
  const gtmData = {
    signup: gtmPayloadString,
    login: gtmPayloadString,
  };

  const isLoginSignUpModalOpen = useSelector(
    (state: State) => state.app.eventsLoginSignUpModalOpen
  );

  const router = useRouter();

  const shouldShowGoogleButton =
    isLoginSignUpModalOpen ||
    ['/signup', '/login', '/signup-email'].includes(router.pathname);

  return (
    <Styled.ExternalSignInButtonsContainer>
      {/* Add isLoginSignUpModalOpen to prevent conflict with One Tap */}
      {shouldShowGoogleButton && (
        <Styled.ExternalSignInButtonWrapper>
          <GoogleTagManager tag="amplitude_login-click_login_page_google">
            <SignInWithGoogle signInSource={signInSource} gtmData={gtmData} />
          </GoogleTagManager>
        </Styled.ExternalSignInButtonWrapper>
      )}
      <Styled.ExternalSignInButtonWrapper>
        <GoogleTagManager tag="amplitude_login-click_login_page_facebook">
          <LoginWithFacebookButton
            signInSource={signInSource}
            gtmData={gtmData}
          />
        </GoogleTagManager>
      </Styled.ExternalSignInButtonWrapper>
      {showLinkedIn && (
        <Styled.ExternalSignInButtonWrapper>
          <GoogleTagManager tag="amplitude_login-click_login_page_linkedin">
            <LoginWithLinkedInButton
              signInSource={signInSource}
              gtmData={gtmData}
            />
          </GoogleTagManager>
        </Styled.ExternalSignInButtonWrapper>
      )}
    </Styled.ExternalSignInButtonsContainer>
  );
};
