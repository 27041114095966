import { Typography } from 'glints-aries';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const Message = styled.div`
  padding: 14px 21px;
  margin: 14px 0;
  background-color: #f8f8f9;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.22);
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
`;

export const WarningMessage = styled.div`
  padding: 16px 24px;
  margin: 16px 0;
  background-color: #fffaf3;
  border-radius: 4px;
  border: 1px solid #c9ba9b;
  color: #794b02;
  text-align: left;
`;

export const ErrorMessage = styled.div`
  padding: 16px 24px;
  margin: 16px 0;
  background-color: #fff6f6;
  border-radius: 4px;
  border: 1px solid #e0b4b4;
  color: #9f3a38;
  text-align: left;
`;

export const MessageHeader = styled(Paragraph).attrs({
  variant: 'subtitle',
  bold: true,
})`
  margin-bottom: 4px;
  color: inherit;
`;
